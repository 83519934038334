// Generated by Framer (f318921)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-glJ44"

const variantClassNames = {mPOENYRrG: "framer-v-c2404o"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({content, height, id, width, ...props}) => { return {...props, n_aUNZa84: content ?? props.n_aUNZa84 ?? "Discovery"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;content?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, n_aUNZa84, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "mPOENYRrG", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-c2404o", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mPOENYRrG"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.2)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", borderBottomLeftRadius: 27, borderBottomRightRadius: 27, borderTopLeftRadius: 27, borderTopRightRadius: 27, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TWFucm9wZS02MDA=", "--framer-font-family": "\"Manrope\", \"Manrope Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "600", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "1.3em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-a378a457-09c9-4e21-8971-31a4dc6ae8ea, rgb(17, 16, 17)))"}}>Discovery</motion.p></React.Fragment>} className={"framer-1wkoyq0"} data-framer-name={"Discovery"} fonts={["GF;Manrope-600"]} layoutDependency={layoutDependency} layoutId={"l_DTRsyCU"} style={{"--extracted-r6o4lv": "var(--token-a378a457-09c9-4e21-8971-31a4dc6ae8ea, rgb(17, 16, 17))", "--framer-paragraph-spacing": "0px"}} text={n_aUNZa84} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-glJ44.framer-tou8he, .framer-glJ44 .framer-tou8he { display: block; }", ".framer-glJ44.framer-c2404o { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 18px; height: 40px; justify-content: center; overflow: visible; padding: 14px 20px 14px 20px; position: relative; width: min-content; }", ".framer-glJ44 .framer-1wkoyq0 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-glJ44.framer-c2404o { gap: 0px; } .framer-glJ44.framer-c2404o > * { margin: 0px; margin-left: calc(18px / 2); margin-right: calc(18px / 2); } .framer-glJ44.framer-c2404o > :first-child { margin-left: 0px; } .framer-glJ44.framer-c2404o > :last-child { margin-right: 0px; } }", ".framer-glJ44[data-border=\"true\"]::after, .framer-glJ44 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 104.5
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"n_aUNZa84":"content"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framercdhq8RCiQ: React.ComponentType<Props> = withCSS(Component, css, "framer-glJ44") as typeof Component;
export default Framercdhq8RCiQ;

Framercdhq8RCiQ.displayName = "Tag 2";

Framercdhq8RCiQ.defaultProps = {height: 40, width: 104.5};

addPropertyControls(Framercdhq8RCiQ, {n_aUNZa84: {defaultValue: "Discovery", displayTextArea: false, title: "Content", type: ControlType.String}} as any)

addFonts(Framercdhq8RCiQ, [{explicitInter: true, fonts: [{family: "Manrope", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/manrope/v15/xn7_YHE41ni1AdIRqAuZuw1Bx9mbZk4jE-_A87jxeN7B.woff2", weight: "600"}]}], {supportsExplicitInterCodegen: true})